// src/pages/machine.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles/WidgetMaquinas.css";

function WidgetMaquinas() {
  const [machine, setMachines] = useState([]);
  const [tipoMaquinas, setTipoMaquinas] = useState({});
  const [plantas, setPlantas] = useState({});

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        // Obtener el token del localStorage
        let token = localStorage.getItem('token') || sessionStorage.getItem('token');

        // Configurar las cabeceras de la solicitud
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        // Obtener las máquinas
        const response = await axios.get(
          "https://api-darpha-production.up.railway.app/api/maquinas", config
        );
        setMachines(response.data);
        console.log("Máquinas:", response.data); // Para verificar el contenido

        // Fetch tipo de maquinas
        const tiposResponse = await axios.get(
          `https://api-darpha-production.up.railway.app/api/tiposmaquina`, config
        );
        const tiposData = {};
        tiposResponse.data.forEach(tipo => {
          tiposData[tipo.idtipomaquina] = tipo.nombretipo; // Asegúrate de que este campo es correcto
        });
        setTipoMaquinas(tiposData);
        console.log("Tipos de máquinas:", tiposData); // Para verificar el contenido

        // Fetch plantas
        const plantasResponse = await axios.get(
          `https://api-darpha-production.up.railway.app/api/plantas`, config
        );
        const plantasData = {};
        plantasResponse.data.forEach(planta => {
          plantasData[planta.idplanta] = planta.nombreplanta; // Asegúrate de que este campo es correcto
        });
        setPlantas(plantasData);
        console.log("Plantas:", plantasData); // Para verificar el contenido

      } catch (error) {
        console.error("Error fetching machines or types:", error);
      }
    };

    fetchMachines();
  }, []);

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th className="th">Id Maquina</th>
            <th className="th">Tipo de Maquina</th>
            <th className="th">Planta</th>
            <th className="th">#</th>
            <th className="th">Ubicación</th>
            <th className="th">Tipo Especifico</th>
            <th className="th">Area Protegida</th>
            <th className="th">Modulo</th>
            <th className="th">Diametro</th>
            <th className="th">Medida Nominal</th>
            <th className="th">Matriz Causa Efecto</th>
          </tr>
        </thead>
        <tbody>
          {machine.map((mach) => (
            <tr key={mach.idmaquina} className="table-row">
              <td className="td">{mach.idmaquina || '-'}</td>
              <td className="td">{tipoMaquinas[mach.idtipomaquina] || 'Desconocido'}</td>
              <td className="td">{plantas[mach.idplanta] || '-'}</td>
              <td className="td">{mach.numero || '-'}</td>
              <td className="td">{mach.ubicacion || '-'}</td>
              <td className="td">{mach.tipoespecifico || '-'}</td>
              <td className="td">{mach.areaprotegida || '-'}</td>
              <td className="td">{mach.modulo || '-'}</td>
              <td className="td">{mach.diametro || '-'}</td>
              <td className="td">{mach.medidonominal || '-'}</td>
              <td className="td">{mach.matrizcausaefecto || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default WidgetMaquinas;
