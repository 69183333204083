import React, { useState } from 'react';
import './Header.css';
import darphaLogo from '../../assets/darpha_logo_digital.png';
import CooperStandarLogo from '../../assets/MIXING.jpg';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="menu__wrapper">
      <div className="menu__bar">
      <img src={darphaLogo} alt="Darpha Logo" style={{ height: '70px' }} onClick={() => navigate('/home')} />
        <nav>
          <ul className={`navigation ${menuOpen ? '' : 'hide'}`}>
            <li>
              <button>
                Empresas
                <svg
                  aria-hidden="true"
                  height="16"
                  viewBox="0 0 16 16"
                  version="1.1"
                  width="16"
                  justify-content="center"
                >
                  <path
                    d="M12.78 5.22a.749.749 0 0 1 0 1.06l-4.25 4.25a.749.749 0 0 1-1.06 0L3.22 6.28a.749.749 0 1 1 1.06-1.06L8 8.939l3.72-3.719a.749.749 0 0 1 1.06 0Z"
                  />
                </svg>
              </button>
              <div className="dropdown__wrapper">
                <div className="dropdown">
                  <ul className="list-items-with-description">
                    <li>
                      <img src={CooperStandarLogo} style={{ height: '70px' }} alt="Cooper Logo" />
                      <div className="item-title">
                        <h3>Cooper Standard</h3>
                        <p>Sellos</p>
                        <p>Mixing</p>
                      </div>
                      <svg
                        aria-hidden="true"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
                      </svg>
                      <div className="item-title">
                        <h3>Cooper Standard</h3>
                        <p>Sellos</p>
                        <p>Mixing</p>
                      </div>
                    </li>
                    {/* Add other menu items here */}
                  </ul>
                </div>
              </div>
            </li>
            {/* Other nav items */}
          </ul>
        </nav>
      </div>
      <div className={`action-buttons ${menuOpen ? '' : 'hide'}`}>
        <a href="#log-in" title="Log in" className="secondary">
          Log Out
        </a>
        <a href="#sign-up" title="Sign up" className="primary">
          Settings
        </a>
        <a href='#home' title='home' className='primary' onClick={() => navigate('/home')} /* Aquí se añade la navegación */>
          Home
        </a>
      </div>
      <button
        aria-label="Open menu"
        className="burger-menu"
        type="button"
        onClick={toggleMenu}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-menu"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 8l16 0" />
          <path d="M4 16l16 0" />
        </svg>
      </button>
    </header>
  );
};

export default Header;
