import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import logo from '../../assets/darpha_logo_digital.png';
import { googleLogout } from '@react-oauth/google';

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Eliminar token del localStorage
    sessionStorage.removeItem('token'); // Eliminar token de sessionStorage
    localStorage.removeItem('userId'); // Eliminar cualquier otro dato del usuario
    sessionStorage.removeItem('userId');
    googleLogout();
    navigate('/'); // Redirigir a la página de login
  };

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <img src={logo} alt="Darpha Logo" />
      </div>
      <div className="sidebar-menu">
        <NavLink exact to="/homeadmin" className="menu-item" activeClassName="active">
          <i className="fas fa-home"></i> {/* Icono de casa */}
          <span>Home</span>
        </NavLink>
        <NavLink to="/profile" className="menu-item" activeClassName="active">
          <i className="fas fa-user"></i> {/* Icono de perfil */}
          <span>Profile</span>
        </NavLink>
        <NavLink to="/addplanta" className="menu-item" activeClassName="active">
          <i className="fas fa-user"></i> {/* Icono de perfil */}
          <span>Add Planta</span>
        </NavLink>
        <NavLink to="/dashboardadmin" className="menu-item" activeClassName="active">
          <i className="fas fa-user"></i> {/* Icono de perfil */}
          <span>Dashboard Admin</span>
        </NavLink>
        <div onClick={handleLogout} className="menu-item">
          <i className="fa-solid fa-right-from-bracket"></i> {/* Icono de logout */}
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
