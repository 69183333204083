import React from 'react';
import { HashRouter as BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Global/login';
import Signup from './Global/Signup';
import Maintenance from './Users/DarphaFireSolutions/Maintenance';
import MachineTypes from './MachineTypes';
import Inventory from './Users/DarphaFireSolutions/Inventory';
import MaintenancePlants from './Users/InspectorUser/MaintenancePlants';
import ProtectedRoute from './services/ProtectedRoute'; // Importa el componente de ruta protegida
import TermsOfService from './Global/TermsOfService';
import PrivacyPolicy from './Global/PrivacyPolicy';
import Welcome from './Users/DefaultUser/DefaultPage';
import Tools from "./Users/DarphaFireSolutions/Tools";
import Profile from "./Global/Profile";
import AddPlantaForm from './Users/AdminUser/AddPlantaForm';
import HomeAdmin from './Users/AdminUser/HomeAdmin';
import HomeInspector from './Users/InspectorUser/HomeInspector';
import ControlPanelAdmin from './Users/AdminUser/ControlPanelAdmin';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/profile" element={<Profile />} />
          <Route 
            path='/welcome' 
            element={
              <ProtectedRoute 
                element={Welcome} 
                requiredRole={[4]} 
              />
            } 
          />
          <Route 
            path='/homeadmin' 
            element={
              <ProtectedRoute 
                element={HomeAdmin} 
                requiredRole={[1]} 
              />
            } 
          />
          <Route 
            path='/home' 
            element={
              <ProtectedRoute 
                element={HomeInspector} 
                requiredRole={[2]} 
              />
            } 
          />
          <Route path='/maintenance' element={
            <ProtectedRoute
              element={Maintenance}
              requiredRole={[1, 2]}
            />
          } />
          <Route path='/maintenance/:planta' element={
            <ProtectedRoute
              element={MaintenancePlants}
              requiredRole={[1, 2]}
            />
          } />
          <Route path='/inventory' element={
            <ProtectedRoute
              element={Inventory}
              requiredRole={[1, 2]}
            />
          } />
          <Route path='/inventory/tools' element={
            <ProtectedRoute
              element={Tools}
              requiredRole={[1, 2]}
            />
          } />
          <Route path='/maintenance/:planta/machinetypes' element={
            <ProtectedRoute
              element={MachineTypes}
              requiredRole={[1, 2]}
            />
          } />
          <Route path='/addPlanta' element={
            <ProtectedRoute
              element={AddPlantaForm}
              requiredRole={[1, 2]}
            />
          } />
          <Route path='/dashboardadmin' element={
            <ProtectedRoute
              element={ControlPanelAdmin}
              requiredRole={[1, 2]}
            />
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;