import React, { useEffect, useState } from "react";
import Sidebar from "../../Global/components/Sidebar.js"; // Importamos Sidebar
import BottomNavBar from '../../Global/components/BottomNavBar.js'; // Importamos BottomNavBar
import './styles/Tools.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isDesktop, isMobile } from "react-device-detect"; // Importamos isMobile y isDesktop

function Tools() {
    const [tools, setTools] = useState([]); // Estado para almacenar las herramientas
    const [loading, setLoading] = useState(true); // Estado para gestionar la carga
    const [newTool, setNewTool] = useState({ nombre: "", descripcion: "" }); // Estado para la nueva herramienta
    const navigate = useNavigate(); // Hook para la navegación

    // Función para obtener las herramientas
    useEffect(() => {
        const fetchTools = async () => {
            try {
                // Obtener el token del localStorage
                let token = localStorage.getItem('token');
                
                // Si no hay token, obtenerlo de sessionStorage
                if (!token) {
                token = sessionStorage.getItem('token');
                }
                const config = { headers: { Authorization: `Bearer ${token}` } };
                const response = await axios.get('https://api-darpha-production.up.railway.app/api/herramientas', config);
                setTools(response.data);
            } catch (error) {
                console.error('Error fetching tools:', error);
                if (error.response && error.response.status === 403) {
                    navigate('/');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchTools();
    }, [navigate]);

    // Función para agregar una herramienta
    const handleAddTool = async (e) => {
        e.preventDefault();
        try {
            let token = localStorage.getItem('token');
            if (!token) {
                token = sessionStorage.getItem('token');
            }
            const config = { headers: { Authorization: `Bearer ${token}` } };
            const response = await axios.post('https://api-darpha-production.up.railway.app/api/herramientas', newTool, config);
            setTools([...tools, response.data]); // Agrega la herramienta a la lista
            setNewTool({ nombre: "", descripcion: "" }); // Limpia el formulario
        } catch (error) {
            console.error('Error adding tool:', error);
        }
    };

    // Función para eliminar una herramienta
    const handleDeleteTool = async (id) => {
        try {
            let token = localStorage.getItem('token');
            if (!token) {
                token = sessionStorage.getItem('token');
            }
            const config = { headers: { Authorization: `Bearer ${token}` } };
            await axios.delete(`https://api-darpha-production.up.railway.app/api/herramientas/${id}`, config);
            setTools(tools.filter(tool => tool.id !== id)); // Actualiza la lista eliminando la herramienta
        } catch (error) {
            console.error('Error deleting tool:', error);
        }
    };

    // Verificamos si es un dispositivo de escritorio o móvil
    let layout;
    if (isDesktop) {
        layout = <Sidebar />;
    } else if (isMobile) {
        layout = <BottomNavBar />;
    }

    return (
        <div className="background-tools">
            {layout} {/* Aquí se muestra Sidebar o BottomNavBar dependiendo del dispositivo */}
            <div className="container-tools">
                <div className="div-large">
                    <div className="title-div-large">
                        <h2>Herramientas Disponibles</h2>
                    </div>

                    {/* Formulario para agregar herramienta */}
                    <form className="add-tool-form" onSubmit={handleAddTool}>
                        <input
                            type="text"
                            placeholder="Nombre de la herramienta"
                            value={newTool.nombre}
                            onChange={(e) => setNewTool({ ...newTool, nombre: e.target.value })}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Descripción"
                            value={newTool.descripcion}
                            onChange={(e) => setNewTool({ ...newTool, descripcion: e.target.value })}
                            required
                        />
                        <button type="submit">Agregar herramienta</button>
                    </form>

                    {loading ? ( // Muestra un mensaje de carga mientras se obtienen los datos
                        <p>Cargando herramientas...</p>
                    ) : (
                        <table className="tools-table">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Fecha de préstamo</th>
                                    <th>Ultima fecha de retorno</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tools.map((tool) => (
                                    <tr key={tool.id}>
                                        <td>{tool.id}</td>
                                        <td>{tool.nombre}</td>
                                        <td>{tool.descripcion}</td>
                                        <td>{tool.fecha_prestamo || 'N/A'}</td>
                                        <td>{tool.fecha_retorno || 'N/A'}</td>
                                        <td>
                                            {/* Botón para eliminar herramienta */}
                                            <button className="eliminar-tools" onClick={() => handleDeleteTool(tool.id)}>
                                                <p className="nombre-eliminar-tools">Eliminar</p>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>

                <div className="div-small-container">
                    <div className="div-small">Estado de uso</div>
                    <div className="div-small">
                        <div className="div-small-content">
                            <div className="div-small-title">Tomar / Regresar herramientas</div>
                            <div className="div-small-subtitle">
                                <div className="tool-buttons">Tomar herramienta</div>
                                <div className="tool-buttons"> Regresar herramienta</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Tools;
