import React from 'react';
import './DefaultPage.css'; // Aquí agregarías los estilos correspondientes
import Sidebar from '../../Global/components/Sidebar';
import BottomNavBar from '../../Global/components/BottomNavBar.js'; // Importamos BottomNavBar
import { isDesktop, isMobile } from "react-device-detect"; // Importamos isMobile y isDesktop

const InfoPage = () => {
  let layout;

  // Verificamos si es un dispositivo de escritorio o móvil
  if (isDesktop) {
    layout = <Sidebar />;
  } else if (isMobile) {
    layout = <BottomNavBar />;
  }
  return (
    <div className="page">
      {layout} {/* Aqui se mostrará Sidebar o BottomNavBar dependiendo del dispositivo */}
    <div className="info-page">

        <h1>Bienvenido a Darpha Maintenance App</h1>
        <p>
          <strong>Objetivo:</strong> La Darpha Maintenance App está diseñada para
          digitalizar la creación y manejo de reportes de mantenimiento de equipos
          contra incendios.
        </p>
        
        <h2>¿Qué puedes hacer en la aplicación?</h2>
        <div className="roles-section">
          <div className="role">
            <h3>Administrador</h3>
            <ul>
              <li>Acceso a todos los reportes de todas las plantas</li>
              <li>Administración de usuarios</li>
              <li>Alta y baja de máquinas</li>
              <li>Generación de reportes</li>
              <li>Acceso a datos de maquinaria, empleados, plantas y reportes</li>
              <li>Control del estado de herramientas y equipo</li>
            </ul>
          </div>
          
          <div className="role">
            <h3>Inspector</h3>
            <ul>
              <li>Creación de reportes en cualquier planta</li>
              <li>Consulta e impresión de reportes en PDF</li>
              <li>Solicitud de herramientas y equipo del inventario</li>
            </ul>
          </div>
          
          <div className="role">
            <h3>Supervisor</h3>
            <ul>
              <li>Acceso solo a reportes de su planta</li>
              <li>Firma de reportes como autorizado</li>
              <li>Consulta e impresión de reportes en PDF</li>
            </ul>
          </div>
        </div>

        <h2>¿Necesitas más acceso?</h2>
        <p>
          Si necesitas acceso a más funcionalidades como Inspector o Supervisor, puedes 
          solicitarlo contactándote con el administrador de la aplicación en el siguiente correo:
        </p>
        <a href="mailto:admin@darphamaintenance.com" className="contact-link">
          admin@darphamaintenance.com
        </a>
        
        <div className="image-section">
          {/* Aquí puedes agregar imágenes alusivas a la app */}
        </div>

    </div>
    </div>
  );
};

export default InfoPage;
