import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import logoImage from "../assets/darpha_logo_digital.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { isDesktop, isMobile } from 'react-device-detect';
import Sidebar from '../Global/components/Sidebar';
import BottomNavBar from '../Global/components/BottomNavBar.js';

// Estilos
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Label = styled.p`
  width: 300px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  background-color: #f7f7f7;
  border-radius: 5px;
  text-align: left;
  color: #333;
`;

const Input = styled.input`
  width: 300px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
`;

const PasswordContainer = styled.div`
  position: relative;
  width: 300px;
`;

const PasswordInput = styled(Input)`
  padding-right: 40px;
`;

const TogglePasswordIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Button = styled.button`
  width: 300px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

// Componente Profile
const Profile = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const navigate = useNavigate();

  // Función para obtener el idUsuario del token JWT
  const getIdFromToken = () => {
    // Obtener el token del localStorage
    let token = localStorage.getItem('token');
        
    // Si no hay token, obtenerlo de sessionStorage
    if (!token) {
      token = sessionStorage.getItem('token');
    }
    else if (!token) {
      setError('No se encontró el token de autenticación');
      return null;
    }
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken.idusuario; // Asegúrate de que este sea el campo correcto en el payload del token
    } catch (error) {
      setError('Error al decodificar el token');
      return null;
    }
  };

  // Cargar datos del perfil del usuario al montar el componente
  useEffect(() => {
    const userId = getIdFromToken();
    if (!userId) return;

    const fetchProfile = async () => {
      // Obtener el token del localStorage
    let token = localStorage.getItem('token');
        
    // Si no hay token, obtenerlo de sessionStorage
    if (!token) {
      token = sessionStorage.getItem('token');
    }
    else if (!token) {
      setError('No se encontró el token de autenticación');
      return null;
    }
      try {
        const response = await axios.get(`https://api-darpha-production.up.railway.app/api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setName(response.data.nombreusuario);
        setEmail(response.data.correoelectronico);
      } catch (error) {
        setError('Error al cargar los datos del perfil');
      }
    };
    fetchProfile();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSaveProfile = async (event) => {
    event.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    try {
      // Obtener el ID de usuario desde el localStorage
    let userId = localStorage.getItem('userId');

    if (!userId) {
      userId = sessionStorage.getItem('userId');
    }else if (!userId) {
      setError('No se encontró el ID de usuario');
      return null;
    }

      // Actualizar el perfil del usuario
      await axios.put(`https://api-darpha-production.up.railway.app/api/users/${userId}`, {
        nombreusuario: name,
        correoelectronico: email,
        contrasena: password || undefined,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      navigate('/dashboard'); // Redirigir tras éxito
    } catch (error) {
      setError('Error al actualizar el perfil. Intenta de nuevo.');
    }
  };

  const handleEdit = () => {
    setIsEditing(true); // Activa el modo de edición
  };

  const handlePasswordChange = () => {
    setShowPasswordFields(true); // Muestra los campos de cambio de contraseña
  };

  let layout;

  if (isDesktop) {
    layout = <Sidebar />;
  } else if (isMobile) {
    layout = <BottomNavBar />;
  }

  return (
    <Container>
      {layout}
      <Logo src={logoImage} alt="Logo" />
      <Title>Perfil de Usuario</Title>

      {/* Mostrar nombre y correo como labels o inputs editables */}
      {!isEditing ? (
        <>
          <Label><strong>Nombre:</strong> {name}</Label>
          <Label><strong>Correo:</strong> {email}</Label>
          <Button onClick={handleEdit}>Editar</Button>
        </>
      ) : (
        <>
          <Input
            type="text"
            placeholder="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type="email"
            placeholder="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </>
      )}

      {/* Mostrar campos de contraseña si se activa */}
      {!showPasswordFields ? (
        <Button onClick={handlePasswordChange}>Cambiar Contraseña</Button>
      ) : (
        <>
          <PasswordContainer>
            <PasswordInput
              type={showPassword ? 'text' : 'password'}
              placeholder="Nueva Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TogglePasswordIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
            />
          </PasswordContainer>
          <PasswordContainer>
            <PasswordInput
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirmar Nueva Contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <TogglePasswordIcon
              icon={showConfirmPassword ? faEyeSlash : faEye}
              onClick={toggleConfirmPasswordVisibility}
            />
          </PasswordContainer>
        </>
      )}

      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Button onClick={handleSaveProfile}>Guardar Cambios</Button>
    </Container>
  );
};

export default Profile;
