// src/components/ControlPanel/ControlPanel.js
import React, { useState } from 'react';
import WidgetMaquinas from './WidgetMaquinas';
// import WidgetPago from './WidgetPago';
import './styles/ControlPanelAdmin.css';
import Sidebar from '../../Global/components/Sidebar.js';
import BottomNavBar from '../../Global/components/BottomNavBar.js'; // Importamos BottomNavBar
import { isDesktop, isMobile } from "react-device-detect"; // Importamos isMobile y isDesktop

const ControlPanelAdmin = () => {
  const [selectedOption, setSelectedOption] = useState('Plantas'); // Estado para la pestaña seleccionada

  const renderWidget = () => {
    switch (selectedOption) {
      case 'Plantas':
        return <div>Plantas Widget Placeholder</div>; // Reemplazar con el widget real de ventas
      case 'Maquinas':
        return <WidgetMaquinas />; // Reemplazar con el widget real de inversiones
      case 'Pagos':
        return <div>Pagos Widget Placeholder</div>; // Reemplazar con el widget real de pagos
      default:
        return null;
    }
  };

  let layout;
  // Verificamos si es un dispositivo de escritorio o móvil
  if (isDesktop) {
    layout = <Sidebar />;
  } else if (isMobile) {
    layout = <BottomNavBar />;
  }

  return (
    <section className="control-panel-main">
      {layout} {/* Aqui se mostrará Sidebar o BottomNavBar dependiendo del dispositivo */}
      <div className="control-panel-box">
        <ul className="options">
          <li 
            className={selectedOption === 'Plantas' ? 'active' : ''} 
            onClick={() => setSelectedOption('Plantas')}
          >
            Plantas
          </li>
          <li 
            className={selectedOption === 'Maquinas' ? 'active' : ''} 
            onClick={() => setSelectedOption('Maquinas')}
          >
            Maquinas
          </li>
          <li 
            className={selectedOption === 'Pagos' ? 'active' : ''} 
            onClick={() => setSelectedOption('Pagos')}
          >
            Pagos Clientes
          </li>
        </ul>
        <div className="course">
          <div className="box">
            {renderWidget()} {/* Renderiza el widget basado en la pestaña seleccionada */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ControlPanelAdmin;
