import React, { useState } from 'react';
import styled from 'styled-components';
import logoImage from "../assets/darpha_logo_digital.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Estilos
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 16px;
  margin-bottom: 30px;
`;

const Input = styled.input`
  width: 300px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
`;

const PasswordContainer = styled.div`
  position: relative;
  width: 300px;
`;

const PasswordInput = styled(Input)`
  padding-right: 40px;
`;

const TogglePasswordIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Button = styled.button`
  width: 300px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const Footer = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 20px;
`;

const Link = styled.a`
  color: #000;
  text-decoration: none;
  font-weight: bold;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

// Componente Signup
const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    setError(''); // Limpiar mensajes de error previos
  
    // Validar que las contraseñas coincidan
    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }
  
    try {
      // Solicitud a la API para registrar usuario
      const response = await axios.post('https://api-darpha-production.up.railway.app/api/signup', {
        nombreusuario: name, // Asumiendo que 'name' es el nombre del usuario
        correoelectronico: email, // 'email' es el correo electrónico
        contrasena: password, // 'password' es la contraseña
        idrol: 4 // Rol de usuario por defecto
      });
  
      // Si el registro es exitoso, redirigir al login
      window.location.href = "/login";
    } catch (error) {
      // Manejar error en caso de que el correo ya exista o algún otro error
      if (error.response && error.response.status === 400) {
        setError('El correo ya está registrado. Intenta con otro.');
      } else {
        setError('Error al registrar. Intenta de nuevo.');
      }
    }
  };
  

  return (
    <Container>
      <Logo src={logoImage} alt="Logo" />
      <Title>Regístrate</Title>
      <Subtitle>Completa tus datos para crear una cuenta</Subtitle>
      <Input
        type="text"
        placeholder="Nombre"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        type="email"
        placeholder="Correo electrónico"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <PasswordContainer>
        <PasswordInput
          type={showPassword ? 'text' : 'password'}
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TogglePasswordIcon
          icon={showPassword ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility}
        />
      </PasswordContainer>
      <PasswordContainer>
        <PasswordInput
          type={showConfirmPassword ? 'text' : 'password'}
          placeholder="Confirmar Contraseña"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <TogglePasswordIcon
          icon={showConfirmPassword ? faEyeSlash : faEye}
          onClick={toggleConfirmPasswordVisibility}
        />
      </PasswordContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>} {/* Muestra mensaje de error */}
      <Button onClick={handleSignup}>Registrarse</Button>
      <Footer>
        Al registrarte, aceptas nuestros <Link href="/#/terms-of-service">Terms of Service</Link> y{' '}
        <Link href="/#/privacy-policy">Privacy Policy</Link>
      </Footer>
    </Container>
  );
};

export default Signup;
