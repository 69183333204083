import './styles/HomeAdmin.css';
import Mantenimiento from '../../assets/Mantenimiento.jpg';
import Inventario from '../../assets/Inventario.jpg';
import { Link } from 'react-router-dom';
import Sidebar from '../../Global/components/Sidebar';
import BottomNavBar from '../../Global/components/BottomNavBar.js'; // Importamos BottomNavBar
import { isDesktop, isMobile } from "react-device-detect"; // Importamos isMobile y isDesktop

function HomeAdmin() {
  let layout;

  // Verificamos si es un dispositivo de escritorio o móvil
  if (isDesktop) {
    layout = <Sidebar />;
  } else if (isMobile) {
    layout = <BottomNavBar />;
  }

  return (
    <div className="page">
      {layout} {/* Aquí se mostrará Sidebar o BottomNavBar según el dispositivo */}

      <div className='home'>
        <div className='container'>
          <h3 className='h3-absolute'>Bienvenido a <span className='darpha'>Darpha Maintenance App</span></h3>
          <h2 className='h2-absolute'>¿Qué vas a hacer?</h2>
          <div className='center'>
            <Link to="/maintenance" className='article-card-link'>
              <div className='article-card'>
                <div className='content'>
                  <p className='date'>Por planta.</p>
                  <p className='title'>Mantenimiento</p>
                </div>
                <img src={Mantenimiento} alt='article-cover' />
              </div>
            </Link>
            <Link to="/inventory" className='article-card-link'>
              <div className='article-card'>
                <div className='content'>
                  <p className='date'>Listado de</p>
                  <p className='title'>Inventario</p>
                </div>
                <img src={Inventario} alt='article-cover' />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAdmin;



