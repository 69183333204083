import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import logoImage from "../assets/darpha_logo_digital.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { GoogleLogin } from '@react-oauth/google';

// Estilos
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
`;

const Logo = styled.img`
  width: 150px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 10px;
  padding-bottom: 0;

`;

const Subtitle = styled.p`
  font-size: 16px;
  margin-bottom: 30px;
`;

const Input = styled.input`
  width: 300px;
  padding: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
`;

const PasswordContainer = styled.div`
  position: relative;
  width: 300px;
`;

const PasswordInput = styled(Input)`
  padding-right: 40px;
`;

const TogglePasswordIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Button = styled.button`
  width: 300px;
  padding: 10px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const Footer = styled.p`
  font-size: 12px;
  color: #666;
  margin-top: 20px;
`;

const Link = styled.a`
  color: #000;
  text-decoration: none;
  font-weight: bold;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const SignupText = styled.p`
  font-size: 14px;
  color: #666;
  margin-top: 10px;
`;

const SignupLink = styled.span`
  color: #000;
  font-weight: bold;
  cursor: pointer;
`;

const Home = () => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleLogin = async () => {
    if (!id || !password) {
      setError('Por favor, completa todos los campos');
      return;
    }
    
    if (!validateEmail(id)) {
      setError('Por favor, ingresa un correo electrónico válido');
      return;
    }
  
    try {
      const response = await axios.post('https://api-darpha-production.up.railway.app/api/login', {
        email: id,
        password
      });
  
      const { token } = response.data;
      const decodedToken = jwtDecode(token);
      const userRole = decodedToken.idrol;
      const userId = decodedToken.idusuario;

      if (rememberMe === true) {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
      } else {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userId', userId);
      }

      switch (userRole) {
        case 1:
          navigate('/homeadmin');
          break;
        case 2:
          navigate('/homeinspector');
          break;
        case 3:
          navigate('/guest');
          break;
        case 4:
          navigate('/welcome');
          break;
        default:
          navigate('/');
      }
    } catch (error) {
      console.error('Error en el login:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError('Error al iniciar sesión. Inténtalo de nuevo.');
      }
    }
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const decodedToken = jwtDecode(response.credential);
      const { email, name } = decodedToken;
  
      const res = await axios.post('https://api-darpha-production.up.railway.app/api/google-login', {
        token: response.credential,
        email,
        nombre: name
      });
      
      const { token } = res.data;

      const decodedTokenApi = jwtDecode(token);
      const userRole = decodedTokenApi.idrol;
      const userId = decodedTokenApi.idusuario;
      if (rememberMe === true) {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
      } else {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('userId', userId);
      }
  
      switch (userRole) {
        case 1:
          navigate('/homeadmin');
          break;
        case 2:
          navigate('/homeinspector');
          break;
        case 3:
          navigate('/guest');
          break;
        case 4:
          navigate('/welcome');
          break;
        default:
          navigate('/');
      }
    } catch (error) {
      console.error('Error en el login con Google:', error);
      if (error.res && error.res.data && error.res.data.message) {
        setError(error.res.data.message);
      } else {
        setError('Error al iniciar sesión. Inténtalo de nuevo.');
      }
    }
  };

  const handleGoogleFailure = (response) => {
    console.log("Google login failed: ", response);
  };

  const refreshToken = async () => {
    try {
      const { data } = await axios.post('https://api-darpha-production.up.railway.app/api/refresh-token', {
        token: localStorage.getItem('token')
      });
      localStorage.setItem('token', data.token);
    } catch (error) {
      console.log("Error al refrescar el token:", error);
    }
  };

  // Nueva verificación de token al cargar la página
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userRole = decodedToken.idrol;
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          switch (userRole) {
            case 1:
              navigate('/home');
              break;
            case 2:
              navigate('/user');
              break;
            case 3:
              navigate('/guest');
              break;
            case 4:
              navigate('/welcome');
              break;
            default:
              navigate('/');
          }
        } else if (decodedToken.exp < currentTime + 600) {
          // Refresca el token si está a punto de expirar
          
          refreshToken();
        }
      } catch (error) {
        console.error('Error al decodificar el token', error);
      }
    }
  }, [navigate]);

  return (
    <Container>
      <Logo src={logoImage} alt="Logo" />
      <Title>Inicia Sesión</Title>
      <Subtitle>Ingresa tu ID y Contraseña</Subtitle>
      <Input
        type="text"
        placeholder="ID"
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      <PasswordContainer>
        <PasswordInput
          type={showPassword ? 'text' : 'password'}
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TogglePasswordIcon
          icon={showPassword ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility}
        />
      </PasswordContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      <Button onClick={handleLogin}>Ingresar</Button>
      
      <div>
        <input 
          type="checkbox" 
          checked={rememberMe} 
          onChange={(e) => setRememberMe(e.target.checked)} 
        />
        <label>Recordarme</label>
      </div>
      
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleFailure}
        useOneTap
      />

      <SignupText>
        ¿No tienes cuenta aún? <SignupLink onClick={() => navigate("/signup")}>Regístrate</SignupLink>
      </SignupText>

      <Footer>
        Al ingresar, aceptas nuestros <Link href="/#/terms-of-service">Terms of Service</Link> y{' '}
        <Link href="/#/privacy-policy">Privacy Policy</Link>
      </Footer>
      <Footer>
        © 2024 Darpha. Todos los derechos reservados.
      </Footer>
    </Container>
  );
};

export default Home;