import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/BottomNavBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { googleLogout } from '@react-oauth/google';

const BottomNavBar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Eliminar token del localStorage
    sessionStorage.removeItem('token'); // Eliminar token de sessionStorage
    localStorage.removeItem('userId'); // Eliminar cualquier otro dato del usuario
    sessionStorage.removeItem('userId');
    googleLogout();
    navigate('/'); // Redirigir a la página de login
  };
  return (
    <div className="bottom-nav">
      <Link to="/home" className="nav-item">
        <FontAwesomeIcon icon={faHome} className="icon" />
      </Link>
      <Link to="/dashboardadmin" className="nav-item">
        <FontAwesomeIcon icon={faSearch} className="icon" />
      </Link>
      <Link to="/profile" className="nav-item">
        <FontAwesomeIcon icon={faUser} className="icon" />
      </Link>
      <Link onClick={handleLogout} className="nav-item">
      <i className="fa-solid fa-right-from-bracket"></i>
      </Link>
    </div>
  );
};

export default BottomNavBar;
