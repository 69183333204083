import React from 'react';
import Header from '../../Global/components/Header.js';
import './styles/Inventory.css';
import { Link } from 'react-router-dom';
import Equipo from '../../assets/Equipo.jpeg';
import Herramienta from '../../assets/Herramienta.jpeg';

function Inventory() {
    return (
        <div>
            <Header />
            <div className='home'>
                <div className='container'>
                    <h3 className='h3-absolute'><span className='highlight'>Inventario</span> Darpha Fire Solutions</h3>
                    <h2 className='h2-absolute'>¿Qué vas a usar?</h2>
                    <div className='center'>
                        <Link to="/inventory/tools" className='article-card-link'>
                            <div className='article-card'>
                                <div className='content'>
                                    <p className='date'></p>
                                    <p className='title'>Herramienta</p>
                                </div>
                                <img src={Herramienta} alt='article-cover' />
                            </div>
                        </Link>
                        <Link to="/inventory" className='article-card-link'>
                            <div className='article-card'>
                                <div className='content'>
                                    <p className='date'>Equipo de</p>
                                    <p className='title'>Instalacion</p>
                                </div>
                                <img src={Equipo} alt='article-cover' />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Inventory;
